//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewEncapsulation,
    TemplateRef,
} from "@angular/core";
import { S25MultiselectComponent } from "../s25.multiselect.component";
import { SearchCriteriaDataUtil } from "../search.criteria.data.util";
import { SearchCriteriaContext } from "../../../services/search/search-criteria/search.criteria.context";
import { SearchCriteriaType } from "../../../pojo/SearchCriteriaI";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { NgbPlacementArray } from "../../s25-popover/popover.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-multiselect-search-criteria")
@Component({
    selector: "s25-ng-multiselect-search-criteria",
    template: `
        @if (usePopover) {
            <s25-ng-multiselect-popup
                [modelBean]="this.modelBean"
                [popoverTemplate]="searchCriteriaMultiselect"
                class="{{ !this.modelBean.isInline ? 'ngRelative' : '' }}"
                [popoverOnBody]="popoverOnBody"
                [popoverPlacement]="popoverPlacement"
                [customPopoverClass]="customPopoverClass"
                [resultsTemplate]="resultsTemplate"
            ></s25-ng-multiselect-popup>
        }
        <ng-template #searchCriteriaMultiselect>
            <s25-ng-multiselect [modelBean]="this.modelBean" (onDone)="onDone.emit($event)"></s25-ng-multiselect>
        </ng-template>
        @if (!usePopover) {
            <ng-container [ngTemplateOutlet]="searchCriteriaMultiselect"> </ng-container>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25MultiselectSearchCriteriaComponent extends S25MultiselectComponent implements OnInit {
    @Input() type: SearchCriteriaType["type"];
    @Input() honorMatching: boolean = false;
    @Input() customFilterValue: string = "";
    @Input() selectedItems: S25ItemI[] = [];
    @Input() popoverOnBody = false;
    @Input() popoverPlacement: NgbPlacementArray = "auto";
    @Input() customPopoverClass?: string;
    @Input() useSecurity?: boolean;
    @Input() usePopover?: boolean = true;
    @Input() resultsTemplate: TemplateRef<unknown>;
    @Output() changed: EventEmitter<S25ItemI[]> = new EventEmitter();
    @Output() onDone: EventEmitter<S25ItemI[]> = new EventEmitter();

    constructor(
        private elem: ElementRef,
        private cdRef: ChangeDetectorRef,
        private z: NgZone,
    ) {
        super(elem, cdRef, z);
    }

    async ngOnInit() {
        this.modelBean = this.modelBean || {};
        const onChange = this.modelBean.onChange;
        this.modelBean.usePopover = this.usePopover;
        this.modelBean.isSearchCriteria = true;
        this.modelBean.onChange = () => {
            this.changed.emit(this.modelBean.selectedItems);
            onChange?.();
        };

        await SearchCriteriaDataUtil.searchCriteriaSetup(
            this.modelBean,
            SearchCriteriaContext.Context[this.type],
            this.selectedItems,
            this.elem.nativeElement,
            this.honorMatching,
            this.customFilterValue,
            this.useSecurity,
        );

        await super.ngOnInit();
    }
}
